import React from 'react';
import './NextBook.css';
import idoat from '../../media/IDOAT.png';
import {MdOutlineStar} from 'react-icons/md';

function NextBook() {
  return (
    <div className='nextBook'>
      <h1>I Died On a Tuesday</h1>
      <h3>Out now in bookshops, supermarkets, and online.</h3>
      {/* <h3></h3> */}
      <div className='row'>
        <div className='col-12 col-md-6 order-2 order-md-1 p-md-0'>
          <img
            src={idoat}
            alt='Upcoming Jane Corry Book I died on a Tuesday Cover'
            className='img-fluid pt-4'
          />
        </div>
        <div className='col-12  col-md-6 order-1 order-md-2'>
          <p className='pt-4'>
            18 year old Janie is leaving home for a new life. World-famous
            rockstar Robbie is harbouring a shocking secret. Witness support
            officer Vanessa is desperate to right the wrongs of her past. They
            tried to bury that fateful day. But now it’s back to haunt them.
          </p>
          <p>Available from bookshops, supermarkets and online.</p>
          <div className='mb-4'>
            <a
              href='https://www.amazon.co.uk/Died-Tuesday-gripping-thriller-bestselling-ebook/dp/B0CNT62W14'
              alt='get I Died On a Tuesday ebook on amazon'
              target='_blank'
              rel='noreferrer'
              className=' py-1 px-2'>
              Click here to order e-book, audio or paperback
            </a>
          </div>
          <div>
            <a
              href='https://www.waterstones.com/book/i-died-on-a-tuesday/jane-corry/9780241996126'
              alt='pre-order I Died On a Tuesday through penguin'
              target='_blank'
              rel='noreferrer'
              className=' py-1 px-2'>
              click here, to order paperback
            </a>
          </div>
        </div>
      </div>
      <div
        id='starreviewsection'
        className=' starreviewsection mt-5 '>
        <p className='stars mb-1'>
          <MdOutlineStar /> <MdOutlineStar /> <MdOutlineStar />{' '}
          <MdOutlineStar /> <MdOutlineStar />
        </p>
        <p className='my-0 mb-2'>
          'A classic Jane Corry - nerve-wracking, spine-tingling and
          heart-warming in equal measures.'
        </p>
        <p className='author my-0'>Nicci French</p>
      </div>
    </div>
  );
}

export default NextBook;
