import React from 'react';
import Footer from './Footer';
import profile from '../media/jcbodyshort.jpg';
import './LinkInBio.css';

export default function LinkinBio() {
  return (
    <div className='linkInBio'>
      <div className='linkInBioPage text-center'>
        <img
          src={profile}
          alt='Jane Corry Profile'
          className='m-auto my-1'
        />
        <h1 className='text-center'>Jane Corry</h1>
        <h3 className='text-center'>important links</h3>
        {/*  */}{' '}
        <a
          href='https://www.amazon.co.uk/Died-Tuesday-gripping-thriller-bestselling-ebook/dp/B0CNT62W14'
          alt='order I Died On a Tuesday ebook'
          className='px-3 linkLightBase '>
          <div className='link m-1 mt-3 m-sm-2 p-3 p-sm-4'>
            I died On a Tuesday - Order on Amazon
          </div>
        </a>
        <a
          href='https://www.waterstones.com/book/i-died-on-a-tuesday/jane-corry/9780241996126'
          alt='pre-order I Died On a Tuesday through penguin'
          className='px-3 linkLightBase '>
          <div className='link m-1 mt-3 m-sm-2 p-3 p-sm-4'>
            I died On a Tuesday - Order at Waterstones
          </div>
        </a>
        
        <a
          href='https://apple.co/4c7aMxH'
          alt='get Audiodeals frm Applebooks from £1.99'
          className='px-3 linkLightBase '>
          <div className='link m-1 mt-3 m-sm-2 p-3 p-sm-4'>
            Audiobook deals with @Applebooks
          </div>
        </a>
        <a
          href='https://www.domestika.org/en/courses/5207-creative-writing-techniques-for-short-stories/janecorryauthor'
          alt='domestica Creative writing Course Link'
          className='px-3 linkLightBase '>
          <div className='link m-1 mt-3 m-sm-2 p-3 p-sm-4'>
            Domestica - Creative Writing Course
          </div>
        </a>
        <a
          href='https://charlielovett42.podbean.com/e/jane-corry-1152024-inside-the-writers-studio-episode-127/'
          alt='Podcast, Charlie Lovett talks with Sunday Times bestselling author Jane Corry about her novel Coming to Find You'
          className='px-3 linkLightBase '>
          <div className='link m-1  m-sm-2 p-3 p-sm-4'>
            Charlie Lovett Podcast Episode with Jane Corry
          </div>
        </a>
      </div>
      <Footer />
    </div>
  );
}
