import React from 'react';
import {useNavigate} from 'react-router-dom';
import "./Reviews.css"

function Reviews() {
  let navigate = useNavigate();
  return (
    <div className='reviews pb-3'>
      <div className='row m-auto align-items-center reviewsection'>
        <div className='col-5 col-sm-4 col-md-3 col-lg-2 ps-4'>
          Psychological Thriller Of The Year!
          <br />
          <span className='highlight'>The Independent</span>
        </div>
        <div className='col-7 col-sm-8 col-md-4 col-lg-3'>
          Jane Corry writes consistently enthralling stories about the dark side
          of family life.
          <br />
          <span className='highlight'>
            PETER JAMES, international best-seller
          </span>
        </div>

        <div className='col-5 col-sm-3 col-md-5 col-lg-3 mt-4 mt-md-0'>
          Wonderfully paced and engaging with characters who kept me in
          heart-stopping suspense throughout. I've loved all Jane's books. This,
          to me, is her very best.
          <br />
          <span className='highlight'>SANDRA HOWARD</span>
        </div>
        <div className='col-7 col-sm-4 col-md-4 col-lg-4 mt-4 mt-md-5 mt-lg-0'>
          The characters were beautifully complex and the small community
          setting realistically portrayed. I loved Billy- he reminded me of one
          of my own children at that age and that's what Jane does so well.
          <br />
          <span className='highlight'>
            JANE SHEMILT, Sunday Times best-seller
          </span>
        </div>

        <div className='col-8 col-sm-5 col-md-5 col-lg-4  mt-4 mt-md-5 ps-2  ps-sm-4 ps-lg-3 '>
          Another twisty treat from Jane Corry. I devoured{' '}
          <i>We All Have Our Secrets</i> in one sitting. It's clever and
          addictive and Jane is so very good at creating characters who get
          right under your skin. I love everything she writes.
          <br />
          <span className='highlight'>
            TERESA DRISCOLL, <br />
            million selling author of <i>I Am Watching You</i>
          </span>
        </div>
        <div className='col-4 col-sm-3 col-md-3 col-lg-2 mt-3 mt-sm-5 mt-md-5 px-sm-4 px-lg-2'>
          Full of intrigue and twists galore, <i>We All Have Our Secrets</i>{' '}
          kept me captivated until the early hours.
          <br />
          <span className='highlight'>
            BA PARIS,
            <br /> international best-seller
          </span>
        </div>

        <div className='col-6 col-sm-4 col-md-3 mt-3 mt-sm-5 ps-lg-3 pe-3'>
          <i>We All Have Our Secrets </i> is a twisty, pacy thriller asking
          questions about who we really are, what's important to us and whether
          we really know the people close to us.
          <br />
          <span className='highlight'>
            GILLY MACMILLAN, <br />
            international best-seller
          </span>
        </div>
        <div className='col-6 col-sm-5 col-md-4 col-lg-3 mt-4 mt-sm-5 pe-sm-4'>
          This is a book that sneaks up on you. A complex and suspenseful story
          about family secrets and relationship driven, beautifully plotted and
          filled with shocking twists.
          <br />
          <span className='highlight'>
            SAMANTHA DOWNING , Sunday Times bestselling author of My Lovely Wife
          </span>
        </div>

        <div className='col-12 col-md-5 col-lg-6 mt-4 mt-md-5 mt-lg-4 p-lg-5'>
          <i>We All Have Our Secrets</i> Our Secrets is a clever, tense and
          compelling thriller about fathers and daughters and the lies we tell
          to ourselves and others. I never knew which of the well-drawn
          characters to believe and I couldn't turn the pages fast enough to
          find out! An excellent read. <br />
          <span className='highlight'>
            CLAIRE DOUGLAS, <br />
            best-selling author of<i> The Couple at No. 9</i>
          </span>
        </div>

        <div className='col-12 col-md-12 col-lg-6 mt-4 mt-md-5 mt-lg-4 mb-4 mb-lg-0 ps-sm-5'>
          I loved the concept and I loved the tension throughout between Emily
          and Francoise. This was a gripping slice of domestic noir. There's
          plenty of twists to keep the reader hooked. Another winner from Jane.
          The pages keep turning in this suspenseful tale of secrets and lies
          and suspicion. Unmissable. Brava.
          <br /> <i>We All Have Our Secrets</i> is sure to be huge.
          <br />
          <span className='highlight'>
            IMRAN MAHMOOD, award-winning author of several books including the
            major BBC drama <i> You Don't Know Me </i>
          </span>
        </div>
      </div>
      <p className='mt-2 mb-0'>
        <button
          onClick={() => {
            navigate('./reviews');
          }}
          className='btnLightDark px-3 py-1 reviewLink'>
          find more reviews here
        </button>
      </p>
    </div>
  );
}

export default Reviews;
